import { useState, FC } from "react";
import { styled, Typography } from "@mui/material";
import { Button, Dropdown, TextField } from "@maestro-org/ui-kit";
import Tooltip from "../../../../components/Tooltip/Tooltip";

import { useDispatch } from "react-redux";

import { Popups } from "../../../../types/popups";
import { updatePopup } from "../../../../redux/actions/popupsActions";

interface Props {
  teamUsersNumber: number;
}

const roleOptions = [
  {
    value: "Member",
  },
  {
    value: "Developer",
  },
  {
    value: "Admin",
  },
];

const TeamCardInvite: FC<Props> = ({ teamUsersNumber }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [role, setRole] = useState<string[]>([]);

  const isDisabled = !(email && role.length) || teamUsersNumber >= 5;

  const handleChangeEmail = (e: any) => setEmail(e.target.value);

  const handleChangeRole = (value: any) => {
    setRole(value);
  };

  const handleInvite = () => {
    dispatch(
      updatePopup({ popup: Popups.addTeamMember, status: true, prefilled: { email, role: role[0].toLowerCase() } }),
    );

    setEmail("");
    setRole([]);
  };

  return (
    <Wrapper>
      <StyledTextField fieldLabel="Invite by email" variant="outlined" value={email} onChange={handleChangeEmail} />
      <StyledDropdown
        variant="outlined"
        options={roleOptions}
        value={role}
        fieldLabel="Role"
        onChange={handleChangeRole}
        helperText=""
        MenuProps={{
          PaperProps: {
            style: { transform: "translateY(8px)" },
          },
        }}
      />
      <StyledTooltip
        title={
          <Typography variant="paragraphSmall" color="grey.A200">
            You have reached the limit of 5 users per team.
          </Typography>
        }
        disableHoverListener={!(teamUsersNumber >= 5)}
      >
        <ButtonWrapper>
          <StyledButton disabled={isDisabled} onClick={handleInvite}>
            Invite
          </StyledButton>
        </ButtonWrapper>
      </StyledTooltip>
    </Wrapper>
  );
};

const StyledButton = styled(Button)({
  "&.MuiButton-root": {
    padding: "14px 34px",
  },

  "&.Mui-disabled": {
    background: "#CCCCCC",
    color: "#999999",
  },
});

const Wrapper = styled("div")({
  display: "flex",
  alignItems: "end",
  gap: "20px",

  "& > div:first-child": {
    width: "100%",
  },

  "& > div:nth-child(2)": {
    width: "fit-content",
  },

  "& .MuiBox-root": {
    width: "140px",
  },
});

const StyledDropdown = styled(Dropdown)(({ theme }) => ({
  maxWidth: "180px",

  "& .MuiSelect-select": {
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px !important",
    borderWidth: "1px !important",
  },

  "& label": {
    left: "0px !important",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.textfield.border.main} !important`,
    borderRadius: "4px",
    width: "100%",
  },

  "& .MuiOutlinedInput-root": {
    padding: "12px 16px !important",
  },

  "& .MuiFormLabel-root.Mui-error": {
    color: "#DC6675 !important",
  },

  "& .MuiInputLabel-shrink.Mui-focused": {
    color: `${theme.palette.primary.main} !important`,
  },
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  zIndex: "100",
  "& .MuiTooltip-tooltip": {
    maxWidth: "216px",
    padding: "16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "6px",
  },
}));

const ButtonWrapper = styled("div")({
  // display: "flex",
});

export default TeamCardInvite;
