import { Chip } from "@maestro-org/ui-kit";
import { styled } from "@mui/material";
import React from "react";

import { ArtistIcon, ComposerIcon, ConductorIcon, VirtuosoIcon } from "../Icons";
import { SubscriptionPlan } from "../../types/subscription";

interface Props {
  plan: SubscriptionPlan;
  old?: boolean;
  textColor?: string;
}

const getIcon = {
  [SubscriptionPlan.artist]: <ArtistIcon />,
  [SubscriptionPlan.composer]: <ComposerIcon />,
  [SubscriptionPlan.conductor]: <ConductorIcon />,
  [SubscriptionPlan.virtuoso]: <VirtuosoIcon />,
};

const PlanChip = ({ plan, textColor, old }: Props): JSX.Element => {
  const getNewLabel = () => {
    if (plan === SubscriptionPlan.artist) {
      return {
        label: old ? `(old) ARTIST` : "ARTIST",
      };
    }
    if (plan === SubscriptionPlan.virtuoso) {
      return {
        label: "VIRTUOSO",
      };
    }
    if (plan === SubscriptionPlan.composer) {
      return {
        label: old ? `(old) COMPOSER` : "COMPOSER",
      };
    }
    if (plan === SubscriptionPlan.conductor) {
      return {
        label: old ? `(old) CONDUCTOR` : "CONDUCTOR",
      };
    }
  };
  return (
    <StyledChip
      textColor={textColor || "#0F0311"}
      color="custom"
      hexColor="#D166E0"
      label={
        <>
          {getIcon[plan]}
          {getNewLabel()?.label}
        </>
      }
    />
  );
};

const StyledChip = styled(Chip)<{ textColor: string }>(({ theme, textColor }) => ({
  borderRadius: "43px",
  background: theme.palette.grey[50],
  padding: "8px 16px",
  height: "auto",

  "&:hover": {
    background: theme.palette.grey[50],
  },

  "& .MuiChip-label": {
    color: textColor,
    fontWeight: `${theme.typography.h6.fontWeight} !important`,
    lineHeight: "20px",
    fontSize: "14px !important",
    display: "flex",
    alignItems: "center",
    columnGap: "8px",

    "& svg": {
      width: "20px",
      height: "20px",
    },

    "& path": {
      fill: theme.palette.primary.main,
    },
  },

  "& svg": {
    display: "inline-flex",
  },
}));

export default PlanChip;
