import { Loader } from "@maestro-org/ui-kit";
import { addDays, differenceInDays, eachDayOfInterval, format, getTime, startOfDay } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";

import { SubscriptionPlan } from "../../../types/subscription";

import {
  getCreditUsage,
  getUser,
  getUserLoading,
  getUserSubscriptions,
  getTeamOwner,
} from "../../../redux/reducers/usersReducer";
import { getIsAuthorized, getSubscriptionPlan } from "../../../redux/reducers/authReducer";

import SettingsCard from "./SettingsCard";
import PlanChip from "../../../components/Chip/PlanChip";
import Divider from "../../../components/Divider/Divider";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { InfoIcon, UpgradeIcon } from "../../../components/Icons";

import { pages } from "../../../lib/routeUtils";
import { numberDecorator } from "../../../lib/numberDecorator";
import LineChart from "../../../components/LineChart/LineChart";
import { UserSubscriptions } from "../../../types/user";
import { getNewSubscriptions } from "../../../redux/reducers/stripeReducer";
import { getSubscriptionsServer } from "../../../redux/actions/stripeActions";
import { InfoTooltipIcon } from "../../../components/Icons";

import {
  getDaysBetweenBilling,
  getDaysUntilNextBilling,
  getEmptyCreditLeftDays,
  getEmptyCreditUsageData,
} from "../../../lib/creditUsage";
import { getUserCreditUsageServer } from "../../../redux/actions/userActions";
import { getAllSubscriptionTiers } from "../../../redux/reducers/stripeReducer";
import { getSubscriptiontTiers } from "../../../redux/actions/stripeActions";
import { AnnualProps, MonthlyProps } from "./APIPackages";

import { UserRoles } from "../../../types/roles";

const compareTwoDates = (date1: string, date2: string) => {
  try {
    return format(new Date(date1), "yy MM dd") === format(new Date(date2), "yy MM dd");
  } catch (error) {
    return false;
  }
};

const CurrentSubscriptionPlan = () => {
  const isAuthorized = useSelector(getIsAuthorized);
  const subscriptionPlan = useSelector(getSubscriptionPlan);
  const loadingUser = useSelector(getUserLoading);
  const user = useSelector(getUser);
  const creditUsage = useSelector(getCreditUsage);
  const allSubscriptions = useSelector(getUserSubscriptions);
  const plan = useSelector(getSubscriptionPlan);
  const allSubscriptionPlans = useSelector(getNewSubscriptions);
  const allSubscriptionTiers = useSelector(getAllSubscriptionTiers);
  const [oldSubscriptionId, setOldSubscriptionId] = useState<string | null>(null);
  const [oldSubscription, setOldSubscription] = useState<UserSubscriptions | null>(null);

  const owner = useSelector(getTeamOwner);

  const showOwnerData = user.team_id && !(user.team_role_name === UserRoles.OWNER);
  const target = showOwnerData ? owner : user;

  const [lastBillingDate, setLastBillingDate] = useState<Date>();
  const [nextBillingDate, setNextBillingDate] = useState<Date>();

  const getSubscriptionData = () => {
    if (!isAuthorized) return;

    if (subscriptionPlan === SubscriptionPlan.artist) {
      const artistPlan = allSubscriptionPlans.find(({ name }) => subscriptionPlan === name);
      if (!artistPlan) return;
      const credits_per_month = artistPlan?.credits_per_month / 1000000;
      return {
        price: `$0/mo`,
        credits_per_month: `${credits_per_month}M/mo`,
        rate: `N/A`,
        plan: SubscriptionPlan.artist,
      };
    }
    if (subscriptionPlan === SubscriptionPlan.conductor && !oldSubscription) {
      const conductorPlan = allSubscriptionPlans.find(({ name }) => subscriptionPlan === name);
      if (!conductorPlan) return;
      let currentTier: AnnualProps | MonthlyProps | undefined;

      if (target?.tier_id) {
        currentTier =
          allSubscriptionTiers?.annual_tiers.find(({ id }) => id === target.tier_id) ||
          allSubscriptionTiers?.monthly_tiers.find(({ id }) => id === target.tier_id);
      }
      if (!currentTier) return;
      const price_per_month =
        "price_per_year" in currentTier ? currentTier.price_per_year : currentTier.price_per_month;
      const credits_per_month = currentTier.credits_per_month / 1000000;
      const rate = currentTier.price_per_credit * 100000;
      return {
        price: "price_per_year" in currentTier ? `$${price_per_month}/yr` : `$${price_per_month}/mo`,
        credits_per_month: `${credits_per_month}M/mo`,
        rate: `$${Number(rate.toFixed(2))} per 100k`,
        plan: SubscriptionPlan.conductor,
      };
    }
  };

  const dispatch = useDispatch();

  const showDiagram = subscriptionPlan === SubscriptionPlan.conductor || subscriptionPlan === SubscriptionPlan.virtuoso;

  const currentPlan = allSubscriptionPlans.find((subscription) => subscription.name === plan);

  const allCreditsStr = currentPlan?.credits_per_day;
  const monthlyCreditsStr = currentPlan?.credits_per_month;

  const allCredits = allCreditsStr ? +allCreditsStr || 0 : 0;
  const monthlyCredits = monthlyCreditsStr ? +monthlyCreditsStr || 0 : 0;

  const initialChartData = getEmptyCreditUsageData(lastBillingDate);

  useEffect(() => {
    if (!isAuthorized || !user) return;

    setLastBillingDate(target?.last_billing_date ? new Date(target.last_billing_date * 1000) : new Date());
    setNextBillingDate(target?.next_billing_date ? new Date(target.next_billing_date * 1000) : new Date());
  }, [isAuthorized, user, owner, showOwnerData]);

  useEffect(() => {
    if (!lastBillingDate || !nextBillingDate) return;
    dispatch(getUserCreditUsageServer({ days: initialChartData.length || 1 }));
  }, [lastBillingDate, nextBillingDate]);

  const creditsUsageData = initialChartData.map((emptyItem) => {
    const usageItem = creditUsage.find((usageItem) =>
      compareTwoDates(
        emptyItem.date,
        new Date(usageItem.date).toLocaleDateString("en-US", {
          timeZone: "UTC",
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }),
      ),
    );
    return usageItem
      ? {
          date: format(new Date(usageItem.date), "MM/dd/yyyy h:mm:ss a"),
          value: +usageItem.credits_used || 0,
          emptyValue: false,
        }
      : emptyItem;
  });

  const spentCredits = showDiagram
    ? creditsUsageData.reduce((acc, item) => acc + item.value, 0)
    : target?.credits_used
    ? +target.credits_used || 0
    : 0;

  const passedDaysPercent =
    ((getDaysBetweenBilling(lastBillingDate, nextBillingDate) - getDaysUntilNextBilling(nextBillingDate)) /
      getDaysBetweenBilling(lastBillingDate, nextBillingDate)) *
    100;

  useEffect(() => {
    setOldSubscriptionId(target ? target.subscription_id : null);
  }, [user, owner, showOwnerData]);

  const oldSubscriptionMap = useMemo(() => {
    if (allSubscriptions) {
      return allSubscriptions.reduce((acc: { [id: string]: UserSubscriptions }, subscription: UserSubscriptions) => {
        acc[subscription.id] = subscription;
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [allSubscriptions]);

  useEffect(() => {
    if (oldSubscriptionMap && oldSubscriptionId && oldSubscriptionMap[oldSubscriptionId]) {
      setOldSubscription(oldSubscriptionMap[oldSubscriptionId]);
    }
  }, [oldSubscriptionMap, oldSubscriptionId]);

  const getIsOldPlan = () => {
    if (oldSubscription) {
      if (
        oldSubscription.subscription_name === SubscriptionPlan.composer ||
        oldSubscription.subscription_name === SubscriptionPlan.conductor
      ) {
        return {
          old: true,
        };
      } else {
        return {
          old: false,
        };
      }
    }
  };

  useEffect(() => {
    dispatch(getSubscriptionsServer());
    dispatch(getSubscriptiontTiers());
  }, []);

  return (
    <SettingsCard>
      <PlanWrapper>
        <Title>
          <DesktopOnly>
            <Typography variant="h6" color="grey.A200">
              Subscription plan
            </Typography>
          </DesktopOnly>
          <MobileOnly>
            <Typography variant="h6" color="grey.A200">
              Subscription
            </Typography>
          </MobileOnly>
          <ChipWrapperMobileOnly>
            <PlanChip plan={subscriptionPlan} old={getIsOldPlan()?.old} />
          </ChipWrapperMobileOnly>
          <Link to={pages.settings()}>
            <UpgradeWrapper>
              <UpgradeIcon />
              <Typography variant="paragraphMedium" color="primary.main">
                Upgrade
              </Typography>
              {getIsOldPlan()?.old && (
                <StyledTooltip
                  enterTouchDelay={0}
                  title={
                    <TooltipText>
                      Maestro's subscription plans are here! You have until July 31st, 2024, to update your plan.
                      Otherwise, you will be automatically migrated to the $9/mo Conductor tier.
                    </TooltipText>
                  }
                >
                  <InfoIconWrapper>
                    <InfoTooltipIcon />
                  </InfoIconWrapper>
                </StyledTooltip>
              )}
            </UpgradeWrapper>
          </Link>
        </Title>
        <ChipWrapperDestopOnly>
          <PlanChip plan={subscriptionPlan} old={getIsOldPlan()?.old} />
        </ChipWrapperDestopOnly>
      </PlanWrapper>
      <PlanInfo>
        {(subscriptionPlan === SubscriptionPlan.artist || subscriptionPlan === SubscriptionPlan.conductor) &&
          !oldSubscription && (
            <>
              <PlanInfoItem>
                <Typography color="grey.400" variant="paragraphMedium">
                  Price
                </Typography>
                <Typography color="grey.A200" variant="h5">
                  {getSubscriptionData()?.price}
                </Typography>
              </PlanInfoItem>
              <PlanInfoItem>
                <Typography color="grey.400" variant="paragraphMedium">
                  Credits
                </Typography>
                <Typography color="grey.A200" variant="h5">
                  {getSubscriptionData()?.credits_per_month}
                </Typography>
              </PlanInfoItem>
              <PlanInfoItem>
                <Typography color="grey.400" variant="paragraphMedium">
                  Rate
                </Typography>
                <Typography color="grey.A200" variant="h5">
                  {getSubscriptionData()?.rate}
                </Typography>
              </PlanInfoItem>
            </>
          )}
      </PlanInfo>
      <Divider />
      {loadingUser ? (
        <Loader />
      ) : showDiagram ? (
        <CreditsDiagram
          data={creditsUsageData}
          passedDaysPercent={passedDaysPercent}
          lastBillingDate={lastBillingDate}
          nextBillingDate={nextBillingDate}
        />
      ) : (
        <CreditsSlider creditsUsed={spentCredits} allCredits={allCredits} monthlyCredits={monthlyCredits} />
      )}
    </SettingsCard>
  );
};

const DesktopOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const MobileOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const ChipWrapperMobileOnly = styled("div")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.up("sm")]: {
    display: "none",
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiChip-root": {
      padding: "0",
    },
  },
}));

const ChipWrapperDestopOnly = styled("div")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const PlanWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "& svg": {
    flexShrink: "0",
  },

  [theme.breakpoints.down("sm")]: {
    alignItems: "flex-start",
  },
}));

const Title = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "4px",

  [theme.breakpoints.down("sm")]: {
    rowGap: "8px",
  },
}));

const TooltipText = styled(Typography)({
  color: "#333333",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
});

const InfoIconWrapper = styled("div")({
  height: "16px",
});

const UpgradeWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const PlanInfo = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: "40px",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: "24px",
  },
}));

const PlanInfoItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "6px",
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: "236px",
    padding: "8px 16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    display: "flex",
    alignItems: "center",
    columnGap: "9px",
  },

  "& svg": {
    width: "13px",
    height: "16px",
  },
}));

interface CreditsSliderProps {
  creditsUsed: number;
  allCredits: number;
  monthlyCredits: number;
}

const CreditsSlider = ({ creditsUsed, allCredits, monthlyCredits }: CreditsSliderProps) => {
  const availableCredits = allCredits - creditsUsed;
  const monthlyMillionCredits = Math.floor(monthlyCredits / 1000000);

  return (
    <RemainingCreditsWrapper>
      <RemainingCreditsValues>
        <CreditsRow>
          <Typography color="grey.A200" variant="paragraphMedium">
            {numberDecorator(availableCredits)} credits remaining
          </Typography>
          <StyledTooltip
            enterTouchDelay={0}
            title={
              <TooltipText>
                Plan is capped at {numberDecorator(allCredits)} credits/day (~{monthlyMillionCredits}M/mo) and renews
                daily. Unused credits are not rolled over.
              </TooltipText>
            }
          >
            <InfoIconWrapper>
              <InfoTooltipIcon />
            </InfoIconWrapper>
          </StyledTooltip>
        </CreditsRow>
        <Typography color="grey.A200" variant="paragraphMedium">
          {Math.floor((availableCredits / allCredits) * 100) || 0}%
        </Typography>
      </RemainingCreditsValues>
      <RemainingCreditsBar variant="determinate" value={Math.floor((availableCredits / allCredits) * 100) || 0} />
    </RemainingCreditsWrapper>
  );
};

const CreditsRow = styled("div")({
  display: "flex",
  gap: "8px",
  alignItems: "center",
});

const RemainingCreditsBar = styled(LinearProgress)(({ theme }) => ({
  height: "12px",
  background: theme.palette.grey[100],
  borderRadius: "8px",

  "& .MuiLinearProgress-bar": {
    borderRadius: "8px",
  },
}));

const RemainingCreditsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const RemainingCreditsValues = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

interface CreditsDiagramProps {
  data: {
    date: string;
    value: number;
  }[];
  passedDaysPercent: number;
  lastBillingDate?: Date;
  nextBillingDate?: Date;
}

const CreditsDiagram = ({ data, passedDaysPercent, nextBillingDate }: CreditsDiagramProps) => {
  const daysLeft = getDaysUntilNextBilling(nextBillingDate);

  const spentCredits = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <CreditsDiagramWrapper>
      <Row>
        <Typography variant="paragraphMedium" color="grey.A200">
          {numberDecorator(spentCredits)} credits spent
        </Typography>
        <Typography variant="paragraphMedium" color="grey.A200">
          {daysLeft} days left
        </Typography>
      </Row>
      <ChartsWrapper>
        <LineChartWrapper passedDaysPercent={passedDaysPercent}>
          <LineChart height={22} units="credits" data={data} />
        </LineChartWrapper>
        <LineChartWrapper passedDaysPercent={100 - passedDaysPercent}>
          <RightChartWrapper>
            <LineChart
              height={22}
              units="credits"
              data={getEmptyCreditLeftDays(nextBillingDate)}
              strokeDasharray="3 3"
            />
          </RightChartWrapper>
        </LineChartWrapper>
      </ChartsWrapper>
    </CreditsDiagramWrapper>
  );
};

const Row = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const CreditsDiagramWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const ChartsWrapper = styled("div")({
  display: "flex",
});

const LineChartWrapper = styled("div")<{ passedDaysPercent: number }>(({ passedDaysPercent }) => ({
  width: `${passedDaysPercent}%`,
}));

const RightChartWrapper = styled("div")({
  position: "relative",
  right: "10px",
});

export default CurrentSubscriptionPlan;
