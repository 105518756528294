import { Button, Dropdown, TextField } from "@maestro-org/ui-kit";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { styled, Theme, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { updatePopup } from "../../redux/actions/popupsActions";
import { getAddNewProjectDialog } from "../../redux/reducers/popupsReducer";
import { createProjectsServer, getProjectsNetworks } from "../../redux/actions/projectsActions";
import { getChainsNetworks } from "../../redux/reducers/projectsReducer";

import { getIsAuthorized } from "../../redux/reducers/authReducer";

import { getCreateProjectFields, initialValuesCreateProject } from "../../forms/createProject/form";
import { createProjectSchema } from "../../forms/createProject/validation";
import { CreateProjectFormValues } from "../../forms/createProject/types";

import Dialog from "./Dialog";

import { Popups } from "../../types/popups";
import { NewEntryField, NewEntryFieldTypes } from "../../types/newEntry";
import { Blockchain, blockchainLabels, Network, networkLabels } from "../../types/project";
import { CreateProjectFields } from "../../forms/createProject/types";

import { Option } from "../../types/newEntry";

const AddNewProjectDialog = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const chainsNetworks = useSelector(getChainsNetworks);
  const isAuthorized = useSelector(getIsAuthorized);

  const networkMapping: { [key: string]: Network } = {
    mainnet: Network.Mainnet,
    preprod: Network.Preprod,
    preview: Network.Preview,
    testnet: Network.Testnet,
  };

  const getBlockchainIcon = (blockchain: string) => {
    switch (blockchain) {
      case "Bitcoin":
        return <img src={"/images/blockchains/Bitcoin.png"} alt="Bitcoin" style={{ width: "18px", height: "18px" }} />;
      case "Cardano":
        return <img src={"/images/blockchains/Cardano.png"} alt="Cardano" style={{ width: "18px", height: "18px" }} />;
      case "Dogecoin":
        return (
          <img src={"/images/blockchains/Dogecoin.png"} alt="Dogecoin" style={{ width: "18px", height: "18px" }} />
        );
      default:
        return null;
    }
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onSubmit = (values: CreateProjectFormValues) => {
    const networkLabel = values.network[0];
    const network = Object.keys(networkMapping).find((key) => networkMapping[key] === networkLabel.toLowerCase());

    const blockchainLabel = values.blockchain[0];
    const blockchain = Object.keys(blockchainLabels).find(
      (key) => blockchainLabels[key as keyof typeof blockchainLabels] === blockchainLabel,
    );

    if (!network || !blockchain) return;
    addProject(values.name, networkMapping[network], blockchain as Blockchain);
  };

  const addProject = (name: string, network: Network, blockchain: Blockchain) => {
    handleClose();
    formik.resetForm();
    dispatch(createProjectsServer({ name, network, chain: blockchain }));
  };

  const isOpen = useSelector(getAddNewProjectDialog);

  const formik = useFormik({
    initialValues: initialValuesCreateProject,
    validationSchema: createProjectSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const { values, setFieldValue, setFieldTouched, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  const networkOptions: Option[] = values.blockchain.length
    ? chainsNetworks
        .find((chainNetwork) => chainNetwork.chain.toLowerCase() === (values.blockchain[0] as string).toLowerCase())
        ?.network.map((network) => ({
          value: capitalizeFirstLetter(networkMapping[network.toLowerCase()] || network),
          disabled: false,
        })) || []
    : [];

  if (networkOptions.some((option) => option.value === capitalizeFirstLetter(Network.Mainnet))) {
    networkOptions.sort((a, b) => {
      if (a.value === capitalizeFirstLetter(Network.Mainnet)) return -1;
      if (b.value === capitalizeFirstLetter(Network.Mainnet)) return 1;
      return 0;
    });
  }

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.addNewProject, status: false }));
  };

  const handleDropdownChange = (value: string[], name: string) => {
    if (name === "blockchain" && values.network) {
      const blockChainValue = value[0]?.toLowerCase();
      if (!blockChainValue) return;
      const networkValue = values.network[0];
      const networkOptions =
        chainsNetworks.find((chainNetwork) => chainNetwork.chain.toLowerCase() === blockChainValue)?.network || [];
      if (!networkOptions.includes(networkValue)) {
        setFieldValue("network", [], true);
      }
    }
    setFieldValue(name, value, true);
  };

  const handleDropdownBlur = (name: string) => {
    setFieldTouched(name, true, true);
  };

  const checkError = (name: string) => !!errors[name as keyof typeof errors] && touched[name as keyof typeof touched];

  const getField = (field: NewEntryField) => ({
    [NewEntryFieldTypes.text]: (
      <StyledTextfield
        label={field.label}
        variant="outlined"
        name={field.name}
        value={values[field.name as keyof typeof values]}
        onChange={handleChange}
        onBlur={handleBlur}
        error={checkError(field.name) as boolean}
        fieldLabel={field.fieldLabel}
      />
    ),
    [NewEntryFieldTypes.select]: (
      <StyledSelect
        label={field.label}
        name={field.name}
        fullWidth
        onBlur={() => handleDropdownBlur(field.name)}
        value={values[field.name as keyof typeof values] as any}
        placeholder={""}
        fieldLabel={field.fieldLabel}
        onChange={(value) => handleDropdownChange(value, field.name)}
        options={field.name === CreateProjectFields.network ? networkOptions : field.options || []}
        startAdornment={field.name === CreateProjectFields.blockchain ? getBlockchainIcon(values[field.name][0]) : null}
        error={checkError(field.name) as boolean}
        MenuProps={{
          PaperProps: {
            style: {
              ...getMenuStyle(theme, checkError(field.name) as boolean),
            },
          },
        }}
      />
    ),
  });

  useEffect(() => {
    if (!isAuthorized) return;
    dispatch(getProjectsNetworks());
  }, [isAuthorized]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Typography color="grey.A200" variant="h5">
          Add new project
        </Typography>
        <FormFields>{getCreateProjectFields.map((field: NewEntryField) => getField(field)[field.type])}</FormFields>
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button type="submit" onMouseDown={(e) => e.preventDefault()} disabled={!formik.isValid || !formik.dirty}>
            Save project
          </Button>
        </Actions>
      </Form>
    </Dialog>
  );
};

const StyledTextfield = styled(TextField)({
  "& fieldset": {
    borderRadius: "4px !important",
    borderWidth: "1px !important",
  },

  "& .MuiInputBase-root": {
    padding: "12.5px 16px",
  },

  "& .MuiFormLabel-root.Mui-error": {
    color: "#DC6675 !important",
  },
});

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const FormFields = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const StyledSelect = styled(Dropdown)(({ theme }) => ({
  paddingLeft: "0",

  "& .MuiSelect-select": {
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px !important",
    borderWidth: "1px !important",
  },

  "& .MuiInputBase-inputAdornedStart": {
    "& span": {
      paddingLeft: "14px",
    },
  },

  "& img": {
    position: "absolute",
    left: "7px",
  },
}));

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",

  "& .MuiButtonBase-root": {
    height: "44px",
  },
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey["A200"],

  "&:hover": {
    background: theme.palette.grey[50],
    color: theme.palette.grey["A200"],
  },
}));

const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
  width: width ? `${width}px` : "auto",
  background: theme.palette.common.white,
  borderRadius: theme.borderRadius.sm,
  boxShadow: "none",
  border: `2px solid ${error ? theme.palette.dropdown.border.error : theme.palette.dropdown.border.main}`,
  transform: "translateY(5px)",
});

export default AddNewProjectDialog;
