import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, Chip, Loader, TextField } from "@maestro-org/ui-kit";

import { getAccountDetailsFields, initialValuesAccountDetails } from "../../../forms/accountDetails/form";
import { AccountDetailsFields, AccountDetailsFormValues } from "../../../forms/accountDetails/types";
import { accountDetailsSchema } from "../../../forms/accountDetails/validation";

import {
  getAuth0FuncData,
  getAuthData,
  getIsUpdatingUserInfo,
  getSubscriptionPlan,
} from "../../../redux/reducers/authReducer";
import { setIsUpdatingUserInfo, updateUserMetadataServer } from "../../../redux/actions/authActions";

import { LoginType } from "../../../types/auth";

import Divider from "../../../components/Divider/Divider";
import UserImage from "../../../components/UserImage/UserImage";
import PlanChip from "../../../components/Chip/PlanChip";
import SettingsCard from "./SettingsCard";
import { GithubIcon, GoogleIcon } from "../../../components/Icons";

import { UserSubscriptions } from "../../../types/user";
import {
  getUserSubscriptions,
  getUser,
  getUserOrganization,
  getIsOrganizationUpdating,
} from "../../../redux/reducers/usersReducer";
import { SubscriptionPlan } from "../../../types/subscription";
import { getSubscriptionsServer } from "../../../redux/actions/stripeActions";

import { updateUserOrganisationServer, setUserOrganization } from "../../../redux/actions/userActions";

import { DetailsCopyIcon } from "../../../components/Icons/DetailsCopyIcon";

import { toast } from "react-toastify";

const AccountDetails = () => {
  const userData = useSelector(getAuthData);
  const auth0Data = useSelector(getAuth0FuncData);
  const allSubscriptions = useSelector(getUserSubscriptions);
  const user = useSelector(getUser);
  const isUpdatingUserInfo = useSelector(getIsUpdatingUserInfo);
  const subscriptionPlan = useSelector(getSubscriptionPlan);
  const organization = useSelector(getUserOrganization);
  const isUpdatingOrganization = useSelector(getIsOrganizationUpdating);

  const [oldSubscriptionId, setOldSubscriptionId] = useState<string | null>(null);
  const [oldSubscription, setOldSubscription] = useState<UserSubscriptions | null>(null);

  const dispatch = useDispatch();

  const firstName = userData?.user_metadata?.firstName;
  const lastName = userData?.user_metadata?.lastName;

  const onSubmit = (values: AccountDetailsFormValues) => {
    dispatch(updateUserMetadataServer({ firstName: values.firstName, lastName: values.lastName, email: values.email }));
    dispatch(setIsUpdatingUserInfo({ status: true }));
    dispatch(updateUserOrganisationServer(values.organization));
  };

  const handleCancelClick = () => {
    setFieldValue("firstName", firstName);
    setFieldValue("lastName", lastName);
    setFieldValue("organization", organization);
  };

  const { values, touched, errors, handleChange, handleBlur, setFieldValue, handleSubmit } = useFormik({
    initialValues: initialValuesAccountDetails,
    validationSchema: accountDetailsSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const checkError = (name: string) => !!errors[name as keyof typeof errors] && touched[name as keyof typeof touched];

  const hasRequiredFieldErrors = !!(checkError("firstName") || checkError("lastName") || checkError("email"));

  const getEndEmailAdornment = () => {
    if (auth0Data.loginType === LoginType.Regular) return;
    return (
      <EndEmailAdornment>
        {auth0Data.loginType === LoginType.Google ? (
          <GoogleIcon />
        ) : (
          <DarkIconWrapper>
            <GithubIcon />
          </DarkIconWrapper>
        )}
        <DesktopOnly>
          <Typography variant="paragraphSmall" color="grey.A200">{`Connected with ${
            auth0Data.loginType === LoginType.Google ? "Google" : "GitHub"
          }`}</Typography>
        </DesktopOnly>
      </EndEmailAdornment>
    );
  };

  useEffect(() => {
    if (!userData) return;
    setFieldValue(AccountDetailsFields.firstName, firstName);
    setFieldValue(AccountDetailsFields.lastName, lastName);
    setFieldValue(AccountDetailsFields.email, userData.email);
    setFieldValue(AccountDetailsFields.organization, organization);
  }, [userData, organization]);

  const changed = useMemo(
    () =>
      (firstName !== values.firstName ||
        lastName !== values.lastName ||
        userData.email !== values.email ||
        organization !== values.organization) &&
      touched,
    [userData, organization, values],
  );

  useEffect(() => {
    if (user) {
      setOldSubscriptionId(user.subscription_id);
    } else {
      setOldSubscriptionId(null);
    }
  }, [user]);

  const oldSubscriptionMap = useMemo(() => {
    if (allSubscriptions) {
      return allSubscriptions.reduce((acc: { [id: string]: UserSubscriptions }, subscription: UserSubscriptions) => {
        acc[subscription.id] = subscription;
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [allSubscriptions]);

  useEffect(() => {
    if (oldSubscriptionMap && oldSubscriptionId && oldSubscriptionMap[oldSubscriptionId]) {
      setOldSubscription(oldSubscriptionMap[oldSubscriptionId]);
    }
  }, [oldSubscriptionMap, oldSubscriptionId]);

  const getIsOldPlan = () => {
    if (oldSubscription) {
      if (
        oldSubscription.subscription_name === SubscriptionPlan.composer ||
        oldSubscription.subscription_name === SubscriptionPlan.conductor
      ) {
        return {
          old: true,
        };
      } else {
        return {
          old: false,
        };
      }
    }
  };

  useEffect(() => {
    dispatch(getSubscriptionsServer());
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <SettingsCard>
        <TopCredentials>
          <Typography color="grey.A200" variant="h6">
            User profile
          </Typography>
        </TopCredentials>
        <UserDetailsWrapper>
          <UserDetails>
            <UserImage size={80} />
            <NameAndPlanWrapper>
              <UserName color="grey.A200">{`${firstName || ""} ${lastName || ""}`}</UserName>
              <ChipWrapper>
                <PlanChip plan={subscriptionPlan} old={getIsOldPlan()?.old} />
              </ChipWrapper>
            </NameAndPlanWrapper>
            {/* <ChangePhotoWrapper>
              <StyledButton size="xsmall">
                Choose photo
              </StyledButton>
              <PhotoRequirments>
                JPG, PNG and WEBP (max. 6MB)
              </PhotoRequirments>
            </ChangePhotoWrapper> */}
          </UserDetails>
          {/* <PictureActions>
            <Button size="xsmall" onClick={handleClick}>
              {isUploadingImage ? <Loader color="secondary" size={25} /> : "Edit profile picture"}
            </Button>
            <DeleteButton size="xsmall" onClick={handleDeletePicture}>
              Delete
            </DeleteButton>
          </PictureActions>
          <input type="file" hidden ref={fileInput} onChange={handleInputChange} /> */}
        </UserDetailsWrapper>
        <Divider />
        <FormFieldsWrapper>
          {getAccountDetailsFields.map((field) => (
            <FoormFieldWrapper key={field.name} fullWidth={field.fullWidth}>
              <StyledTextField
                name={field.name}
                type={field.type}
                variant="outlined"
                value={values[field.name]}
                onChange={handleChange}
                onBlur={handleBlur}
                errorPosition={field.fullWidth ? "bottom" : "top"}
                error={checkError(field.name)}
                disabled={field.name === AccountDetailsFields.email}
                endIcon={field.name === AccountDetailsFields.email && getEndEmailAdornment()}
                fieldLabel={field.fieldLabel}
              />
            </FoormFieldWrapper>
          ))}
        </FormFieldsWrapper>
        {changed && (
          <Actions>
            <CancelButton onClick={handleCancelClick} variant="secondary" onMouseDown={(e) => e.preventDefault()}>
              <Typography color="grey.A200">Cancel</Typography>
            </CancelButton>
            <Button type="submit" onMouseDown={(e) => e.preventDefault()} disabled={hasRequiredFieldErrors}>
              {isUpdatingUserInfo || isUpdatingOrganization ? (
                <Loader color="secondary" size={25} />
              ) : (
                <Typography>Save</Typography>
              )}
            </Button>
          </Actions>
        )}
      </SettingsCard>
    </form>
  );
};

const UserDetailsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    rowGap: "32px",
  },
}));

const UserDetails = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  columnGap: "16px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    rowGap: "16px",
  },
}));

const CopyWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "8px",
  cursor: "pointer",
}));

const UserId = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color: "#000000",
}));

const UserName = styled(Typography)(({ theme }) => ({
  color: theme.palette.social.dark,
  fontSize: "24px",
  fontWeight: "400",
  lineHeight: "28px",
  maxWidth: "220px",
}));

const TopCredentials = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const ChangePhotoWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  marginLeft: "auto",
  flexDirection: "column",
  gap: "16px",
}));

const StyledButton = styled(Button)({
  marginLeft: "auto",
});

const ChipWrapper = styled("div")({
  display: "inline-flex",
  width: "auto",
});

const PhotoRequirments = styled(Typography)(({ theme }) => ({
  color: theme.palette.menu.grayText,
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.textfield.border.main} !important`,
    borderRadius: "4px",
  },

  "& .MuiOutlinedInput-root": {
    padding: "12px 16px !important",
  },

  "& .MuiFormLabel-root.Mui-error": {
    color: "#DC6675 !important",
  },
}));

const PictureActions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const DeleteButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey[50],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey[50],
    color: theme.palette.grey.A200,
  },
}));

const NameAndPlanWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",

  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
  },
}));

const FormFieldsWrapper = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  rowGap: "20px",
});

const FoormFieldWrapper = styled("div")<{ fullWidth: boolean }>(({ fullWidth, theme }) => ({
  flexBasis: fullWidth ? "100%" : "calc(50% - 12px)",

  [theme.breakpoints.down("sm")]: {
    flexBasis: "100%",
  },
}));

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "16px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],

  "&:hover": {
    background: theme.palette.grey["50"],
  },
}));

const EndEmailAdornment = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const DarkIconWrapper = styled("div")(({ theme }) => ({
  "& svg": {
    fill: theme.palette.common.black,
  },
}));

const DesktopOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export default AccountDetails;
