import { UserSubscriptions } from "../types/user";
import { Package } from "../redux/reducers/stripeReducer";
import { IUser } from "../redux/reducers/usersReducer";
import { SubscriptionPlan } from "../types/subscription";

export const getCurrentPlanName = (
  oldPlans: UserSubscriptions[],
  newSubscriptions: Package[],
  user: IUser,
): SubscriptionPlan => {
  const currentOldSubscription = oldPlans.find((item) => item.id === user.subscription_id);
  const currentNewSubscription = newSubscriptions.find((item) => item.id === user.package_id);

  return currentOldSubscription?.subscription_name || currentNewSubscription?.name || SubscriptionPlan.artist;
};
