import { Button, TextField } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import { updatePopup } from "../../redux/actions/popupsActions";
import { getRenameTeamtDialog, getPrefilled } from "../../redux/reducers/popupsReducer";

import Dialog from "./Dialog";

import { Popups } from "../../types/popups";

import { updateTeamName } from "../../redux/actions/teamsActions";

const RenameTeamDialog = () => {
  const isOpen = useSelector(getRenameTeamtDialog);
  const dispatch = useDispatch();
  const teamName = useSelector(getPrefilled);

  const [newTeamName, setNewTeamName] = useState(teamName || "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTeamName(event.target.value);
  };

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.renameTeam, status: false }));
  };

  const handleRename = () => {
    handleClose();
    dispatch(updateTeamName(newTeamName));
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Wrapper>
        <Typography color="grey.A200" variant="h5">
          Rename "{teamName}"
        </Typography>
        <TextField variant="outlined" fieldLabel="Team name" onChange={handleChange} />
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button onClick={handleRename}>Rename</Button>
        </Actions>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey["50"],
    color: theme.palette.grey.A200,
  },
}));

export default RenameTeamDialog;
